import { Search } from "@mui/icons-material";
import { Box, Button, Grid, TextField } from "@mui/material";
import React, { useState, useEffect, useContext } from 'react';
import ParamService from "../../services/param.service";
import SelectStudentCpnt from "./select-student.cpnt";
import SelectSubjectCpnt from "./select-subject.cpnt";
import { AppContext } from "../../context/app.context";

export default function SearchExerciseCpnt({ setLoading }) {
    const appContext = useContext(AppContext);
    
    const [selectedStudent, setSelectedStudent] = useState('SELF');
    const [subjectId, setSubjectId] = useState('ALL');
    const [exerciseName, setExerciseName] = useState('');

    useEffect(() => {
        const exerciseFilter = appContext.data.exerciseFilter;
        if (exerciseFilter){
            setSelectedStudent(exerciseFilter.owner);
            setSubjectId(exerciseFilter.subjectId);
            setExerciseName(exerciseFilter.exerciseName);
        }
    }, []);

    const handleChangeSubject = (e) => {
        setSubjectId(e.target.value);
    };

    const handleExerciseNameChange = (e) => {
        setExerciseName(e.target.value);
    };

    const onSearch = () => {
        setLoading(true);
        const filter = { exerciseName, subjectId, owner: selectedStudent };
        appContext.setData({exerciseFilter: filter});
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <div>
                        <TextField
                            fullWidth
                            label="Name"
                            name="exerciseName"
                            value={exerciseName}
                            onChange={handleExerciseNameChange}
                            variant="outlined"
                            margin="normal"
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <SelectStudentCpnt
                        selectedStudent={selectedStudent}
                        setSelectedStudent={setSelectedStudent}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <SelectSubjectCpnt
                        name="subject"
                        value={subjectId}
                        label='Matiere'
                        handleChange={handleChangeSubject}
                        fetchFunction={ParamService.fetchAllSubjects}
                        valueLabelMap={{ value: 'id', label: 'name' }} />
                </Grid>

                <Grid item xs={12} container justifyContent="flex-end">
                    <Button variant="contained" color="primary" onClick={onSearch} startIcon={<Search />}>
                        Rechercher
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}