import { createContext, useState } from "react";

export const AppContext = createContext({
    currentUser: {},
    setCurrentUser: () =>{},
    data: {
        exerciseFilter: {
            selectedStudent: undefined,
            subjectId: 'ALL',
            exerciseName: ''
        }        
    },
    setData: () => {}
});

function AppContextProvider({ children }) {
    const [currentUser, setUser] = useState(undefined);
    const [data, setData] = useState({});

    const setCurrentUser = (user) => {
        setUser(user);
    }

    const updateData = (data) => {
        setData(data);
    }

    const value = {
        currentUser: currentUser,
        setCurrentUser: setCurrentUser,
        data: data,
        setData: updateData,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppContextProvider;