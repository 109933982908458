import { Box, TextField } from "@mui/material";

const AddMathQuestionCpnt = ({ formData, errors, setFormData }) => {

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Box>
            <TextField
                label="Saisir votre question ici!"
                name="question"
                value={formData.question}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
                error={!!errors.question}
                helperText={errors.question}
            />

            <TextField
                label="Saisir la reponse ici!"
                name="answer"
                value={formData.answer}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
                error={!!errors.answer}
                helperText={errors.answer}
            />
        </Box>
    );
}

export default AddMathQuestionCpnt;