import React, { useContext, useState, useRef } from 'react';
import { Button, Box } from '@mui/material/';
import Alert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import 'react-quill/dist/quill.snow.css';

import ParamService from '../../services/param.service';
import PsnDropdown from '../components/psn-dropdown.component';
import waitAndExecute from '../../utils/js-utils';
import QuestionService from '../../services/question.service';
import { AppContext } from '../../context/app.context';
import AddOpenQuestionCpnt from './add-open-question.cpnt';
import AddMathQuestionCpnt from './add-math-question.cpnt';
import AddDictationCpnt from './add-dictation.cpnt';
import PsnLoadingButton from '../components/psn-loading-button';

const AddQuestionForm = ({ selectedStudent, selectedExercise, errors, setErrors }) => {
    const appContext = useContext(AppContext);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [formData, setFormData] = useState({
        subject: '',
        question_type: '',
        question: '',
        answer: '',
    });
    const addDictationCpntRef = useRef();

    const handleChangeQuestionType = (e) => {
        const value = e.target.value;

        setFormData({
            ...formData,
            [e.target.name]: value
        });

        setErrors({});
    };

    const validateExercise = (exercise) => exercise ? '' : 'Le champ "Exercice" est obligatoire';
    const validateStudent = (student) => student ? '' : 'Le champ "Eleve" est obligatoire';
    const validateQuestionType = (question_type) => question_type ? '' : 'Le champ "Type de question" est obligatoire';
    const validateQuestion = (question) => question ? '' : 'Le champ "Question" est obligatoire';
    const validateAnswer = (answer) => '';

    const validate = () => {
        let tempErrors = {};
        tempErrors.exercise = validateExercise(selectedExercise);
        tempErrors.student = validateStudent(selectedStudent);
        tempErrors.question_type = validateQuestionType(formData.question_type);
        tempErrors.question = validateQuestion(formData.question);
        tempErrors.answer = validateAnswer(formData.answer);
        setErrors(tempErrors);

        return Object.values(tempErrors).every(x => x === '');
    };

    const handleSubmit = async (e) => {
        //e.preventDefault();

        if (validate()) {
            const parentId = appContext.currentUser.id;
            await QuestionService.addQuestion(parentId, {
                exerciseId: selectedExercise,
                questionTypeCode: formData.question_type,
                text: formData.question,
                authorAnswer: formData.answer
            });

            resetForm();
            if (addDictationCpntRef.current) {
                addDictationCpntRef.current.reset();
            }

            setShowSuccessMessage(true);
            await waitAndExecute(2000, () => setShowSuccessMessage(false));
        } else {
            if (formData.question === '') {
                setFormData({
                    ...formData,
                    question: undefined
                });
            }
            setShowErrorMessage(true);
            await waitAndExecute(2000, () => setShowErrorMessage(false));
        }
    };

    const resetForm = () => {
        setFormData({
            ...formData,
            question: '',
            answer: '',
        });
    }

    return (
        <div>
            {showSuccessMessage &&
                <Alert severity="info">Operation effectuee avec succes</Alert>
            }

            {showErrorMessage &&
                <Alert severity="error">Il y a des champs en erreur!!!</Alert>
            }

            <form onSubmit={handleSubmit}>
                <PsnDropdown
                    name="question_type"
                    value={formData.question_type}
                    label={'Type de question'}
                    handleChange={handleChangeQuestionType}
                    fetchFunction={ParamService.fetchAllQuestionTypes}
                    valueLabelMap={{ value: 'typeCode', label: 'typeName' }}
                    config={{
                        error: !!errors.question_type,
                        helperText: errors.question_type
                    }} />

                {
                    formData.question_type === 'OPENED_QUESTION' &&
                    <AddOpenQuestionCpnt formData={formData} setFormData={setFormData} errors={errors} />
                }

                {
                    formData.question_type === 'MENTAL_MATH' &&
                    <AddMathQuestionCpnt formData={formData} setFormData={setFormData} errors={errors} />
                }

                {
                    formData.question_type === 'DICTATION' &&
                    <AddDictationCpnt formData={formData} setFormData={setFormData} errors={errors} ref={addDictationCpntRef} />
                }

                <Box display="flex" justifyContent="flex-end">
                    <PsnLoadingButton
                        buttonText='Enregistrer'
                        action={handleSubmit}

                        config={{
                            startIcon: <SaveIcon />,
                            variant: 'contained',
                            margin: 'normal'
                        }} />
                </Box>
            </form>
        </div>
    );
}

export default AddQuestionForm;