
import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Paper, Button, styled, Box, Stack } from '@mui/material/';
import AddIcon from '@mui/icons-material/Add';
import { ArrowBackIos, ExpandMore } from '@mui/icons-material';
import AddQuestionForm from './add-question-form';
import SelectExerciseCpnt from './select-exercise.cpnt';
import AddExercise from './add-exercise';
import ExerciseService from '../../services/exercise.service';
import SelectStudentCpnt from './select-student.cpnt';
import { useNavigate } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const AddQuestionPage = () => {
    const navigate = useNavigate();
    const [exerciseTitle, setExerciseTitle] = useState('Ajouter un exercice');
    const [toggle, setToggle] = useState(false);

    const [exercises, setExercises] = useState([]);
    const [selectedExercise, setSelectedExercise] = useState(undefined);
    const [selectedStudent, setSelectedStudent] = useState(undefined);
    const [selectedStudentFirstName, setSelectedStudentFirstName] = useState(undefined);
    const [selectedExerciseName, setSelectedExerciseName] = useState(undefined);

    const [errors, setErrors] = useState({});
    const [reload, setReload] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const data = await ExerciseService.fetchByStudentUserCode(selectedStudent);
                setExercises(data);

                const [exercise] = data;
                if (exercise) {
                    setSelectedExercise(exercise.id);
                    setExerciseTitle(selectedStudentFirstName + ': ' + exercise.name);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [selectedStudent, reload]);

    const handleToggle = () => {
        setToggle(!toggle);
    };

    const updateExercises = (exercise) => {
        setReload((prev) => !prev);
        setSelectedExercise(exercise.id);
        setSelectedExerciseName(exercise.name);
        setExerciseTitle(selectedStudentFirstName + ': ' + exercise.name);
    };

    return (
        <Box
            sx={{
                width: '100%',
                padding: 2,
                boxShadow: 3,
                borderRadius: 1,
                marginBottom: 2,
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ 
                    width: '100%',
                    marginBottom: 2,
                }}
            >
                <Typography variant="h3">
                    Gérer les exercices
                </Typography>

                <Button variant="contained" color="primary" startIcon={<ArrowBackIos />} onClick={() => { navigate('/list-exercise')}}>
                    Retourner
                </Button>
            </Stack>

            <div>
                <Accordion expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{exerciseTitle}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={!toggle ? 12 : 6} >
                                <StyledPaper fullwidth={!toggle}>
                                    <Typography variant="h6" component="h2" padding={2}>
                                        Veuillez, si necessaire selectionner l'exercice pour lequel vous voulez assigner la prochaine question
                                    </Typography>

                                    <Box display="flex" justifyContent="flex-end">
                                        <Button
                                            onClick={handleToggle}
                                            color="primary"
                                            variant="contained"
                                            disabled={!selectedStudentFirstName || toggle}
                                            startIcon={<AddIcon />}
                                        >
                                            Ajouter un exercice ({selectedStudentFirstName})
                                        </Button>
                                    </Box>
                                    <SelectStudentCpnt
                                        selectedStudent={selectedStudent}
                                        setSelectedStudent={setSelectedStudent}
                                        selectedAssignmentName={selectedExerciseName}
                                        setSelectedStudentFirstName={setSelectedStudentFirstName}
                                        setAssignmentTitle={setExerciseTitle}
                                        config={{
                                            error: !!errors.student,
                                            helperText: errors.student
                                        }}
                                    />

                                    <SelectExerciseCpnt
                                        selectedExercise={selectedExercise}
                                        setSelectedExercise={setSelectedExercise}
                                        selectedStudentFirstName={selectedStudentFirstName}
                                        exercises={exercises}
                                        setExerciseTitle={setExerciseTitle}
                                        config={{
                                            error: !!errors.exercise,
                                            helperText: errors.exercise
                                        }}
                                    />
                                </StyledPaper>
                            </Grid>
                            {toggle && (
                                <Grid item xs={6}>
                                    <StyledPaper>
                                        <Typography variant="h6">Ajout d'un exercice</Typography>
                                        <AddExercise studentUserCode={selectedStudent} updateExercises={updateExercises} handleToggle={handleToggle} />
                                    </StyledPaper>
                                </Grid>
                            )}
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Questions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AddQuestionForm
                            selectedStudent={selectedStudent}
                            selectedExercise={selectedExercise}
                            errors={errors}
                            setErrors={setErrors}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        </Box>
    );
}

export default AddQuestionPage;