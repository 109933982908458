import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Box, Table, TableContainer, TableHead, TableRow, TableBody, TableCell, Paper, Menu, MenuItem, TableSortLabel, Toolbar, Checkbox, CircularProgress, Link, Divider, Typography } from "@mui/material";
import InfiniteScroll from 'react-infinite-scroll-component';
import { visuallyHidden } from '@mui/utils';
import ExerciseService from '../../services/exercise.service';
import { useNavigate } from 'react-router-dom';
import { ArrowDropDown, PersonAdd, PlaylistRemove, Quiz } from '@mui/icons-material';
import { Button } from '@mui/material';
import UserService from '../../services/user.service';
import { AppContext } from '../../context/app.context';

export default function ListExerciseCpnt({ exerciseIds, addSelectedExerciseId, clearExerciseIds, loading, setLoading }) {
    const appContext = useContext(AppContext);
    const filter = appContext.data.exerciseFilter;
    const navigate = useNavigate();
    const [exercises, setExercises] = useState([]);
    const [reload, setReload] = useState(false);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                
                const data = await ExerciseService.searchExercises({ ...filter, page: 1, size: 15 });

                setExercises(data);

                setLoading(false);
                setPage(1);
                data.length < 15 ? setHasMore(false) : setHasMore(true);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [order, orderBy, filter, reload]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            for (let ex of exercises) {
                addSelectedExerciseId(ex.id);
            }
        } else {
            clearExerciseIds();
        }
    };

    const handleClick = (row) => {
        navigate(`/detail-exercise/${row.id}`, { state: { selectedRow: row } });
    };

    const handleSelect = (id) => {
        addSelectedExerciseId(id);
    };

    const loadMoreData = () => {
        (async () => {
            try {
                const data = await ExerciseService.searchExercises({ ...filter, page: page + 1, size: 15 });

                setExercises((prevExercises) => {
                    const newData = [...prevExercises];
                    for (const newExercise of data) {
                        const exists = exercises.some(exercise => exercise.id === newExercise.id);

                        if (!exists) {
                            newData.push(newExercise);
                        }
                    }

                    return newData;
                });

                setPage((prevPage) => prevPage + 1);
                data.length < 15 ? setHasMore(false) : setHasMore(true);
            } catch (error) {
                console.log(error);
            }
        })();
    };

    const onUserSelected = async (userId) => {
        try {
            const res = await ExerciseService.exercisesToStudent(userId, exerciseIds);
        } catch (error) {
            console.log("An error occurred when assigning exercises to user ID: " + userId);
        }
    }

    const forceReload = () => {
        setReload((prev) => !prev);
        clearExerciseIds();
    }

    if (loading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <ExerciseListToolbar
                selectAll={exerciseIds.length === exercises.length}
                onUserSelected={onUserSelected}
                owner={filter?.owner}
                selectedIds={exerciseIds}
                reload={reload}
                forceReload={forceReload} />
            <TableContainer component={Paper} sx={{ height: 400, overflow: 'auto' }} id="exercisesDiv">
                <InfiniteScroll
                    dataLength={exercises.length}
                    next={loadMoreData}
                    hasMore={hasMore}
                    loader={
                        hasMore && (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )
                    }
                    endMessage={
                        !hasMore && (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <p>Toutes les données ont été récupérées</p>
                                </TableCell>
                            </TableRow>
                        )
                    }
                    scrollableTarget="exercisesDiv"
                    scrollThreshold={0.9}
                >
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="Exercises"
                        size={'medium'}
                        stickyHeader
                    >
                        <ExerciseListHead
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={exercises ? exercises.length : 0}
                        />
                        <TableBody>
                            {exercises && exercises.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={exerciseIds.length > 0}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={exerciseIds.length === 100}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={exerciseIds.includes(row.id)}
                                                onChange={() => handleSelect(row.id)}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            padding="none"
                                        >
                                            <Link onClick={() => handleClick(row)}>{row.name}</Link>

                                        </TableCell>
                                        <TableCell align="right">{row.subject.name}</TableCell>
                                        <TableCell align="right">{row.questionCount}</TableCell>
                                        <TableCell align="right">{row.learningSessionCount}</TableCell>
                                        <TableCell align="right">{row.countDown}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            </TableContainer>
        </Box>
    );
}

function ExerciseListHead(props) {
    const { onSelectAllClick, order, orderBy, selectAll, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={selectAll}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ExerciseListHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function ExerciseListToolbar(props) {
    const { numSelected, onUserSelected, owner, selectedIds, forceReload } = props;
    const navigate = useNavigate();
    const [removeDisabled, setRemoveDisabled] = useState(true);
    const [notRemovable, setNotRemovable] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                let enable = true;

                if (selectedIds.length === 0 || owner === undefined || owner === 'SELF' || owner === 'OTHERS') {
                    setRemoveDisabled(true)
                } else {
                    for (let selectedId of selectedIds) {
                        const hasCurrentId = notRemovable.find(obj => obj.id === selectedId);
                        if (hasCurrentId && !hasCurrentId.removable) {
                            setRemoveDisabled(true);
                            enable = false;
                            break;
                        } else if (hasCurrentId && hasCurrentId.removable) {
                            continue;
                        }
                        else {
                            const removable = await ExerciseService.isExerciseUnassignableFromUser(selectedId, owner);
                            setNotRemovable((prev) => {
                                return [...prev, { id: selectedId, removable }]
                            });

                            if (!removable) {
                                setRemoveDisabled(true);
                                enable = false;
                                break;
                            }
                        }
                    }

                    if (enable) {
                        setRemoveDisabled(false);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [selectedIds, owner]);

    const handleExerciseUnassign = async () => {
        try {
            const res = await ExerciseService.unAssignExercisesFromStudent(owner, selectedIds);
            forceReload();
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Toolbar
            sx={[
                {
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                },
                numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                },
            ]}
        >
            <Box sx={{ flex: '1 1 100%' }}>
                <Button variant="contained" color="primary" onClick={() => { navigate('/add-question'); }} startIcon={<Quiz />}>
                    Créer un exercice
                </Button>
            </Box>

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <AssignExercisesMenuButton onUserSelected={onUserSelected} />
                <Button
                    sx={{ whiteSpace: 'nowrap', marginLeft: '10px' }}
                    variant="outlined" color="primary"
                    onClick={handleExerciseUnassign}
                    startIcon={<PlaylistRemove />}
                    disabled={removeDisabled}>
                    Dé-assigner les exercices
                </Button>
            </Box>
        </Toolbar>
    );
}

ExerciseListToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onUserSelected: PropTypes.func.isRequired,
    owner: PropTypes.any.isRequired,
    selectedIds: PropTypes.array.isRequired,
    forceReload: PropTypes.func.isRequired
};

const AssignExercisesMenuButton = ({ onUserSelected }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [students, setStudents] = useState();

    useEffect(() => {
        (async () => {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                const data = await UserService.fetchStudentByParentId(currentUser.id);
                setStudents(data);

            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (id) => {
        console.log('selected ID: ' + id);
        onUserSelected(id);
        handleClose();
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (students) {
        return (
            <div>
                <Button
                    aria-controls={open ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                    sx={{
                        whiteSpace: 'nowrap'
                    }}
                    endIcon={<ArrowDropDown />}
                >
                    Assigner les exercices
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button'
                    }}
                >
                    {students.map(option => (
                        <Box>
                            <MenuItem
                                value={option.id}
                                key={option.id}
                                onClick={() => handleMenuItemClick(option.id)}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    px: 2,
                                    gap: 2
                                }}>
                                    <PersonAdd />
                                    <Typography variant="h6">{option.firstName} {option.lastName}</Typography>
                                </Box>
                            </MenuItem>
                            <Divider sx={{ opacity: 0.1 }} />
                        </Box>

                    ))}

                </Menu>
            </div>
        );
    }

}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Exercices',
    },
    {
        id: 'subject',
        numeric: true,
        disablePadding: false,
        label: 'Matiere',
    },
    {
        id: 'questions',
        numeric: true,
        disablePadding: false,
        label: 'Questions',
    },
    {
        id: 'sessions',
        numeric: true,
        disablePadding: false,
        label: 'Sessions',
    },
    {
        id: 'Duree',
        numeric: true,
        disablePadding: false,
        label: 'Countdown (s)',
    },
];