import BASE_URL from '../utils/environment-utils';

const AudioService = {

    getAudioFile: async (audioFile) => {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const authToken = currentUser.token;
        try {
            const response = await fetch(`${BASE_URL}/audio/${audioFile}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch audio: ${response.statusText}`);
            }

            return response;
        } catch (error) {
            console.log(error);
        }
    },
}

export default AudioService;