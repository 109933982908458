import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ListExerciseCpnt from "./list-exercise.cpnt";
import SearchExerciseCpnt from "./search-exercise.cpnt";

export default function ListExercise() {
    const [loading, setLoading] = useState(true);
    const [exerciseIds, setExerciseIds] = useState([]);

    const addSelectedExerciseId = (id) => {
        setExerciseIds((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((item) => item !== id)
                : [...prevSelected, id]
        );
    }

    const clearExerciseIds = (id) => {
        setExerciseIds([]);
    }

    return (
        <Box
            sx={{
                width: '100%',
                padding: 2,
                boxShadow: 3,
                borderRadius: 1,
                marginBottom: 2,
            }}
        >
            <Typography variant="h3" component="div">
                Exercices
            </Typography>
            <Box sx={{
                marginBottom: 2,
                border: '1px solid #dedede',
                borderRadius: 1,
                padding: 3
            }}>
                <SearchExerciseCpnt setLoading={setLoading} />
            </Box>

            <ListExerciseCpnt
                exerciseIds={exerciseIds}
                addSelectedExerciseId={addSelectedExerciseId}
                clearExerciseIds={clearExerciseIds}
                loading={loading}
                setLoading={setLoading}
            />
        </Box>


    );
}
