import instance from './axios.config';

const QuestionService = {
    addQuestion: async (parentId, question) => {
        try {
            const axios = await instance();
            const response = await axios.post(`/questions/author/${parentId}`, question);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    findQuestionsByExercise: async (exerciseId) => {
        try {
            const axios = await instance();
            const response = await axios.get(`/questions/exercise/${exerciseId}`);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    searchQuestions: async (filter) => {
        try {
            const axios = await instance();
            const response = await axios.post(`/questions/search`, filter);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    generateSentences: async (words) => {
        try {
            const axios = await instance();
            const response = await axios.post(`/questions/dictation/generate`, words);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

}

export default QuestionService;