
import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material/';
import Alert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import PsnDropdown from "../components/psn-dropdown.component";
import ParamService from '../../services/param.service';
import waitAndExecute from '../../utils/js-utils'
import ExerciseService from '../../services/exercise.service';

const AddExercise = ({ studentUserCode, updateExercises, handleToggle }) => {
    const [subjectId, setSubjectId] = useState('');
    const [subjectCode, setSubjectCode] = useState('');
    const [exerciseName, setExerciseName] = useState('');
    const [countDown, setCountDown] = useState(90);
    const [errors, setErrors] = useState({});
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleChangeSubject = async (e) => {
        const id = e.target.value;
        setSubjectId(id);

        try {
            const subject = await ParamService.findSubjectById(id);
            setSubjectCode(subject?.code);
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeExerciseName = (e) => {
        setExerciseName(e.target.value);
    };

    const handleChangeCountDown = (e) => {
        setCountDown(e.target.value);
    };

    const validate = () => {
        let tempErrors = {};

        tempErrors.exerciseName = exerciseName ? '' : 'This field is required';
        tempErrors.subjectId = subjectId ? '' : 'Subject is required';
        const isPositiveInteger = /^[1-9]\d*$/.test(countDown);
        tempErrors.countDown = isPositiveInteger || countDown === '' ? '' : 'La durée doit être un entier positif.';

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            const savedExercise = await ExerciseService.addExercise(studentUserCode, { 
                    subjectId, 
                    name: exerciseName, 
                    authorId: 5, 
                    countDown: subjectCode === 'MATHS' ? countDown : undefined 
            });
            
            setShowSuccessMessage(true);
            setSubjectId('');
            setExerciseName('');
            setCountDown(90);
            setSubjectCode('');

            updateExercises({ id: savedExercise.exerciseId, name: exerciseName });
            await waitAndExecute(2000, () => setShowSuccessMessage(false));
            handleToggle();
        }

        console.log('Form data: ', subjectId, exerciseName);
    };

    return (

        <div>
            {showSuccessMessage &&
                <Alert severity="info">Operation effectuee avec succes</Alert>
            }

            <form onSubmit={handleSubmit}>
                <PsnDropdown
                    name="subject"
                    value={subjectId}
                    label={'Matiere'}
                    handleChange={handleChangeSubject}
                    fetchFunction={ParamService.fetchAllSubjects}
                    valueLabelMap={{ value: 'id', label: 'name' }}
                    config={{
                        error: !!errors.subjectId,
                        helperText: errors.subjectId
                    }} />
                <TextField
                    label="Saisir le nom de l'exercice!"
                    name="exerciseName"
                    value={exerciseName}
                    onChange={handleChangeExerciseName}
                    margin="normal"
                    variant="outlined"
                    error={!!errors.exerciseName}
                    helperText={errors.exerciseName}
                    fullWidth
                />
                {
                    subjectCode === 'MATHS' &&
                    <TextField
                        label="Durée (secondes)"
                        name="countDown"
                        value={countDown}
                        onChange={handleChangeCountDown}
                        margin="normal"
                        variant="outlined"
                        error={!!errors.countDown}
                        helperText={errors.countDown}
                    />
                }

                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Button onClick={handleToggle} variant="outlined" color="primary" startIcon={<ArrowBackIosIcon />}>
                            Annuler
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" color="primary" margin="normal" startIcon={<SaveIcon />}>
                            Enregistrer
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </div>

    );
}

export default AddExercise;