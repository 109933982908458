import { Search } from "@mui/icons-material";
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useState } from "react";
import ParamService from "../../../services/param.service";
import SelectSubjectCpnt from "../select-subject.cpnt";
import QuestionService from "../../../services/question.service";
import ExerciseService from "../../../services/exercise.service";
import SelectExerciseCpnt from "../select-exercise.cpnt";

const AddQuestionDialog = ({ open, handleClose, exercise }) => {
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState();
    const [questionIds, setQuestionIds] = useState([]);

    const handleSearch = (filter) => {
        setFilter(filter);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await ExerciseService.addQuestions(exercise.id, questionIds);
        handleClose();
    }

    const addSelectedQuestionId = (id) => {
        setQuestionIds((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((item) => item !== id)
                : [...prevSelected, id]
        );
    }

    return (
        <Box>
            <Dialog
                open={open}
                onClose={(event, reason) => { handleClose(event, reason) }}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit,
                    sx: {
                        height: 'auto',
                        maxHeight: '90vh',
                        width: '90%',
                        maxWidth: 'none',
                        overflow: 'auto',
                    },
                }}
            >
                <DialogTitle>Ajout de questions à l'exercice</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Box sx={{
                            marginBottom: 2,
                            border: '1px solid #dedede',
                            borderRadius: 1,
                            padding: 3
                        }}>
                            <QuestionSearchForm handleSearch={handleSearch} setLoading={setLoading} />
                        </Box>

                        <QuestionList
                            filter={filter}
                            loading={loading}
                            setLoading={setLoading}
                            questionIds={questionIds}
                            addSelectedQuestionId={addSelectedQuestionId} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button type="submit">Ajouter</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const QuestionSearchForm = ({ handleSearch, setLoading }) => {
    const [subjectId, setSubjectId] = useState('ALL');
    const [questionText, setQuestionText] = useState('');
    const [author, setAuthor] = useState('SELF');
    const [selfExercise, setSelfExercise] = useState();
    const [othersExercise, setOthersExercise] = useState();

    const onSearch = () => {
        setLoading(true);
        handleSearch({ text: questionText, subjectId, author, selfExercise, othersExercise });
    }

    const handleQuestionTextChange = (e) => {
        setQuestionText(e.target.value);
    }

    const handleChangeSubject = (e) => {
        setSubjectId(e.target.value);
    }

    const handleAuthorChange = (e) => {
        setAuthor(e.target.value);
    };

    const handleSelfExerciseChange = (value) => {
        setSelfExercise(value);
    };

    const handleOthersExerciseChange = (value) => {
        setOthersExercise(value);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        fullWidth
                        label="Name"
                        name="questionText"
                        value={questionText}
                        onChange={handleQuestionTextChange}
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <SelectSubjectCpnt
                        name="subject"
                        value={subjectId}
                        label='Matiere'
                        handleChange={handleChangeSubject}
                        fetchFunction={ParamService.fetchAllSubjects}
                        valueLabelMap={{ value: 'id', label: 'name' }} />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <FormControl component="fieldset">
                        <FormLabel id="authorRG">Auteur</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="authorRG"
                            value={author}
                            name="authorRG"
                            onChange={handleAuthorChange}
                        >
                            <FormControlLabel value="SELF" control={<Radio />} label="Moi" />
                            <FormControlLabel value="OTHERS" control={<Radio />} label="Autres" />
                            <FormControlLabel value="ALL" control={<Radio />} label="Tous" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <SelectExerciseCpnt
                        name="selfExercise"
                        value={selfExercise}
                        label="Mes exercices"
                        fetchFunction={ExerciseService.findSelfExercises}
                        handleChange={handleSelfExerciseChange}
                        valueLabelMap={{ value: 'id', label: 'name' }} />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <SelectExerciseCpnt
                        name="othersExercise"
                        value={othersExercise}
                        label="Exercices des autres"
                        fetchFunction={ExerciseService.findOthersExercises}
                        handleChange={handleOthersExerciseChange}
                        valueLabelMap={{ value: 'id', label: 'name' }} />
                </Grid>

                <Grid item xs={12} container justifyContent="flex-end">
                    <Button variant="contained" color="primary" onClick={onSearch} startIcon={<Search />}>
                        Rechercher
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

const QuestionList = ({ filter, loading, setLoading, questionIds, addSelectedQuestionId }) => {
    const [questions, setQuestions] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                const newFilter = { ...filter, page: 1, size: 15 }
                const data = await QuestionService.searchQuestions(newFilter);

                setQuestions(data);
                setLoading(false);
                setPage(1);
                data.length < 15 ? setHasMore(false) : setHasMore(true);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [filter]);

    const loadMoreData = () => {
        (async () => {
            try {
                const newFilter = { ...filter, page: page + 1, size: 15 }
                const data = await QuestionService.searchQuestions(newFilter);

                setQuestions((prevQuestions) => {
                    const newData = [...prevQuestions];
                    for (const newQuestion of data) {
                        const exists = questions.some(question => question.id === newQuestion.id);

                        if (!exists) {
                            newData.push(newQuestion);
                        }
                    }

                    return newData;
                });

                setPage((prevPage) => prevPage + 1);
                data.length < 15 ? setHasMore(false) : setHasMore(true);
            } catch (error) {
                console.log(error);
            }
        })();
    };

    const handleSelect = (id) => {
        addSelectedQuestionId(id);
    };

    if (loading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <TableContainer component={Paper} sx={{ height: 400, overflow: 'auto' }} id="questionsDiv">
            <InfiniteScroll
                dataLength={questions.length}
                next={loadMoreData}
                hasMore={hasMore}
                loader={
                    hasMore && (
                        <TableRow>
                            <TableCell colSpan={3} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )
                }
                endMessage={
                    !hasMore && (
                        <TableRow>
                            <TableCell colSpan={3} align="center">
                                <p>Toutes les données ont été récupérées</p>
                            </TableCell>
                        </TableRow>
                    )
                }
                scrollableTarget="questionsDiv"
                scrollThreshold={0.9}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                            </TableCell>
                            <TableCell>Question</TableCell>
                            <TableCell>Reponse</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((row, index) => {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={questionIds.includes(row.id)}
                                            onChange={() => handleSelect(row.id)} />
                                    </TableCell>
                                    <TableCell>{row.text}</TableCell>
                                    <TableCell>{row.authorAnswer}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </InfiniteScroll>
        </TableContainer>
    );
}

export default AddQuestionDialog;