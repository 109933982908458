import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import bgImage from "../../assets/images/bg-home.jpg";
import googlePlayBadge from "../../assets/images/google-play-fr.png";
import appleStoreBadge from "../../assets/images/apple-store-fr.png";

const Home = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{ width: '100%', p: 2 }}>
                        <Typography variant="h1" gutterBottom>
                            SPOMYN
                        </Typography>
                        <Typography variant="h2" gutterBottom>
                            La réussite scolaire de vos enfants commence ici!!!
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            N'hésitez pas: téléchargez l'application SPOMYN sur votre mobile et commencez dès maintenant.
                        </Typography>

                        <Grid container spacing={2} direction="row" alignItems="center">
                            <Grid item xs={6}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link href="https://play.google.com/store/apps/details?id=com.spomynm" target="_blank" rel="noopener noreferrer">
                                        <img src={googlePlayBadge} alt="Télécharger dans Google Play" style={{ width: '80', height: 'auto' }} />
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link href="https://apps.apple.com/us/app/spomyn/id6475783203" target="_blank" rel="noopener noreferrer">
                                        <img src={appleStoreBadge} alt="Télécharger dans Apple Store" style={{ width: '80', height: 'auto' }} />
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ width: '100%', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={bgImage} alt="Placeholder" style={{ maxWidth: '100%', height: 'auto' }} />
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <Box sx={{
                    p: 2,
                    borderColor: 'primary.main',
                }}>
                    <Typography variant="h2" gutterBottom>
                        Comment ça fonctionne?
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default Home