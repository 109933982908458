import instance from './axios.config';

const ExerciseService = {
    fetchByStudentUserCode: async (studentUserCode) => {
        try {
            const axios = await instance();
            const response = await axios.get(`/exercises/userCode/${studentUserCode}`);
            console.log(response);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    addExercise: async (userCode, exercise) => {
        try {
            const axios = await instance();
            const response = await axios.post(`/exercises/userCode/${userCode}`, exercise);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    searchExercises: async (filter) => {
        try {
            const axios = await instance();
            const response = await axios.post(`/exercises/search`, filter);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    findSelfExercises: async () => {
        try {
            const axios = await instance();
            const response = await axios.get(`/exercises/self`);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    findOthersExercises: async () => {
        try {
            const axios = await instance();
            const response = await axios.get(`/exercises/others`);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    addQuestions: async (exerciseId, questionIds) => {
        try {
            const axios = await instance();
            const response = await axios.post(`/exercises/${exerciseId}/questions`, questionIds);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    isQuestionRemovableFromExercise: async (exerciseId, questionId) => {
        try {
            const axios = await instance();
            const response = await axios.get(`/exercises/${exerciseId}/question/${questionId}/isRemovable`);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    removeQuestions: async (exerciseId, quesionIds) => {
        try {
            const axios = await instance();
            const response = await axios.put(`/exercises/${exerciseId}/removeQuestions`, quesionIds);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    exercisesToStudent: async (studentId, exercises) => {
        try {
            const axios = await instance();
            const response = await axios.post(`/exercises/exercisesToUser`, {studentId, exercises});

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    isExerciseUnassignableFromUser: async (exerciseId, studentId) => {
        try {
            const axios = await instance();
            const response = await axios.get(`/exercises/${exerciseId}/student/${studentId}/isRemovable`);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },

    unAssignExercisesFromStudent: async (studentId, exerciseIds) => {
        try {
            const axios = await instance();
            const response = await axios.put(`/exercises/unAssignFromStudent/${studentId}`, exerciseIds);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    },
}

export default ExerciseService;