import React, { useState } from 'react';
import { Button, CircularProgress, Box } from '@mui/material';

const PsnLoadingButton = ({ buttonText, action, params, config }) => {
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        await action(params);
        setLoading(false);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <Button
                variant="contained"
                onClick={handleClick}
                disabled={loading}
                sx={{
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    minWidth: 200,
                }}
                {...config}
            >
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    buttonText
                )}
            </Button>
        </Box>
    );
};

export default PsnLoadingButton;