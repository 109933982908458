import { useState, useEffect } from 'react';
import { TextField, MenuItem, } from '@mui/material/';

import UserService from '../../services/user.service';

const SelectStudentCpnt = ({ selectedStudent, setSelectedStudent, config }) => {
    const [students, setStudents] = useState(undefined);


    useEffect(() => {
        (async () => {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                const data = await UserService.fetchStudentByParentId(currentUser.id);
                setStudents(data);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    const handleChange = (e) => {
        const value = e.target.value;
        setSelectedStudent(value);
    };

    return (
        <div>
            {
                students &&
                <TextField
                    select
                    label='Propriétaires'
                    name='student'
                    value={selectedStudent}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    {...config}
                >
                    <MenuItem value="SELF">Que j'ai créés</MenuItem>
                    <MenuItem value="OTHERS">Créés par les autres</MenuItem>
                    {students.map(option => (
                        <MenuItem
                            value={option.id}
                            key={option.id}
                        >
                            Créés pour {option.firstName}
                        </MenuItem>
                    ))}
                </TextField>
            }
        </div>
    );
}

export default SelectStudentCpnt;