import axios from 'axios';

import BASE_URL from '../utils/environment-utils';

const instance = async (noAuthorization) => {
    if (noAuthorization) {
        return axios.create({
            baseURL: BASE_URL,
            timeout: 30000,
            headers: {
                'Content-Type': 'application/json',
            }
        });
    } else {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const token = currentUser.token;
        return axios.create({
            baseURL: BASE_URL,
            timeout: 30000,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
    }
}

export default instance;