import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, } from '@mui/material/';

const SelectExerciseCpnt = ({ value, label, handleChange, fetchFunction }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        (async () => {
            const data = await fetchFunction();
            setOptions([...data]);
        })();
    }, []);

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.name}
            defaultValue={options[1]}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                />
            )}
            value={value}
            onChange={(event, newValue) => {
                handleChange(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
        />
    );
};

export default SelectExerciseCpnt;