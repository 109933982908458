import { SettingsSuggest } from "@mui/icons-material";
import { Box, FormControl, FormLabel, TextField, RadioGroup, FormControlLabel, Radio, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, } from "@mui/material";
import { useState, useImperativeHandle, forwardRef } from "react";
import QuestionService from "../../services/question.service";
import PsnLoadingButton from "../components/psn-loading-button";


const AddDictationCpnt = forwardRef(({ formData, errors, setFormData }, ref) => {
    const [text, setText] = useState('');
    const [creationMode, setCreationMode] = useState('MANUAL');
    const [sentenceLength, setSentenceLength] = useState('SHORT');
    const [sentenceNumber, setSentenceNumber] = useState();
    const [sentenceNumberValid, setSentenceNumberValid] = useState(true);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleTextChange = (e) => {
        setText(e.target.value);

        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSentenceNumberChange = (e) => {
        const inputValue = e.target.value;
        setSentenceNumber(inputValue);

        validatePositiveInteger(inputValue);
    }

    const validatePositiveInteger = (input) => {
        const isPositiveInteger = /^[1-9]\d*$/.test(input);
        setSentenceNumberValid(isPositiveInteger || input === '');
    };

    const handleCreationModeChange = (e) => {
        setCreationMode(e.target.value);
    }

    const handleSentenceLengthChange = (e) => {
        setSentenceLength(e.target.value);
    }

    const onGenerateDictation = async () => {
        const lines = text.split('\n');
        if (text === '') {
            setOpen(true);
            return;
        }

        for (let line of lines) {
            const words = line.split(' ');
            if (words.length > 2) {
                setOpen(true);
                return;
            }
        }

        const data = await QuestionService.generateSentences(
            { sentenceLength, sentenceNumber, words: lines }
        );
        const sentences = data.map(item => item.sentence).join('\n');
        setText(sentences);
        setFormData({
            ...formData,
            question: sentences
        });
    }

    const reset = () => {
        setText('');
    }

    useImperativeHandle(ref, () => ({
        reset,
    }));

    return (
        <Box>
            <Box
                sx={{
                    p: 2,
                }}
            >

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel id="creationModeRG">Mode de creation de la dictee</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="creationModeRG"
                                value={creationMode}
                                name="creationModeRG"
                                onChange={handleCreationModeChange}
                            >
                                <FormControlLabel value="MANUAL" control={<Radio />} label="Manuel" />
                                <FormControlLabel value="AUTO" control={<Radio />} label="Automatique" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                {
                    creationMode === 'AUTO' &&
                    <Grid item container xs={12} spacing={2} alignItems="center">
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel id="sentenceLengthRG">Longueur des phrases</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="sentenceLengthRG"
                                    value={sentenceLength}
                                    name="sentenceLengthRG"
                                    onChange={handleSentenceLengthChange}
                                >
                                    <FormControlLabel value="SHORT" control={<Radio />} label="Courte" />
                                    <FormControlLabel value="MEDIUM" control={<Radio />} label="Moyenne" />
                                    <FormControlLabel value="LONG" control={<Radio />} label="Longue" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Nombre de phrases"
                                name="sentenceNumber"
                                onChange={handleSentenceNumberChange}
                                margin="normal"
                                variant="outlined"
                                error={!sentenceNumberValid}
                                helperText={!sentenceNumberValid ? 'Le nombre de phrases doit être un entier positif.' : ''}
                            />
                        </Grid>

                        <Grid item xs />

                        <Grid item>
                            <PsnLoadingButton
                                buttonText='Generer les phrases'
                                action={onGenerateDictation}
                                config={{
                                    startIcon: <SettingsSuggest />,
                                    variant: 'outlined',
                                }} />
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Erreur sur la liste des mots"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Vous devez fournir chaque mot sur une nouvelle ligne et il ne doit pas avoir plus de deux mots par ligne.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>OK</Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                }
            </Box>


            <TextField
                label="Saisir votre question ici!"
                name="question"
                multiline
                rows={10}
                value={text}
                onChange={handleTextChange}
                margin="normal"
                variant="outlined"
                fullWidth
            />


        </Box>
    );

});

export default AddDictationCpnt;