import React from 'react';
import { TextField, MenuItem, } from '@mui/material/';

const SelectExerciseCpnt = ({selectedExercise, setSelectedExercise, exercises, setExerciseTitle, selectedStudentFirstName, config }) => {

    const handleChange = (e) => {
        const value = e.target.value;
        const [exercice] = exercises.filter((elt) => elt.id === value);
        setSelectedExercise(exercice.id);
        setExerciseTitle(selectedStudentFirstName + ': ' + exercice.name);
    };

    return (
        <div>
            {
                (exercises && selectedExercise) &&
                <TextField
                    select
                    label='Exercices'
                    name='exercise'
                    value={selectedExercise}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    {...config}
                >
                    {exercises.map(option => (
                        <MenuItem
                            value={option.id}
                            key={option.id}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            }
        </div>
    );
}

export default SelectExerciseCpnt;