import { Link } from "react-router-dom";
import { useState } from "react";

import "./Navbar.css";

const Navbar = ({ onSearch, cartItemCount }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSubmit = () => {
    if (searchQuery.trim().length) {
      onSearch(searchQuery.trim());
    }
    setSearchQuery("");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link to="/" className="navbar-brand">
          SPOMYN
        </Link>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <Link to="/contact-us" class="nav-link">
                Nous joindre
              </Link>
            </li>
            <li class="nav-item">
              <Link to="about-us" class="nav-link">
                À propos de nous
              </Link>
            </li>
          </ul>
        </div>

        {
          sessionStorage.getItem('currentUser') ?
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <Link to="/" className="navbar-brand" onClick={() => {
                sessionStorage.removeItem('currentUser');
              }}>
                Déconnexion
              </Link>
            </div>
          </div>
          :
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <Link to="/sign-in" className="navbar-brand">
                Sign In
              </Link>
            </div>
          </div>
        }

      </div>
    </nav>
  );
};

export default Navbar;
