import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, } from '@mui/material/';

const SelectSubjectCpnt = ({ name, value, label, handleChange, fetchFunction, valueLabelMap, disabledItems, config }) => {
    const [options, setOptions] = useState();

    useEffect(() => {
        (async () => {
            const data = await fetchFunction();
            setOptions(data);
        })();
    }, []);

    function isItemDisabled(item) {
        if (disabledItems) {
            const result = disabledItems.filter(elt => item[valueLabelMap.code] === elt);
            return result.length !== 0;
        }
        return false;
    }

    if (options) {
        return (
            <TextField
                select
                label={label}
                name={name}
                value={value}
                onChange={handleChange}
                fullWidth
                margin="normal"
                {...config}
            >
                <MenuItem value="ALL">Toutes les matieres</MenuItem>
                {options.map(option => (
                    <MenuItem
                        value={option[valueLabelMap.value]}
                        key={option[valueLabelMap.value]}
                        disabled={isItemDisabled(option)}
                    >
                        {option[valueLabelMap.label]}
                    </MenuItem>
                ))}
            </TextField>
        );
    };
}


export default SelectSubjectCpnt;