import { Add, Delete, SmartDisplay } from "@mui/icons-material";
import { Table, TableContainer, TableHead, TableRow, TableBody, TableCell, Paper, CircularProgress, Toolbar, Typography, Button, Checkbox, Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ExerciseService from "../../../services/exercise.service";
import QuestionService from "../../../services/question.service";
import AddQuestionDialog from "./add-question.dialog";
import DOMPurify from 'dompurify';
import AudioService from "../../../services/audio.service";

const ListQuestionCpnt = ({ exercise }) => {
    const [questions, setQuestions] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [addQuestionOpen, setAddQuestionOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const data = await QuestionService.findQuestionsByExercise(exercise.id);
                setQuestions(data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [reload]);

    const handleRowClick = (row) => { }

    const handleSelect = (id) => {
        setSelected((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((item) => item !== id)
                : [...prevSelected, id]
        );
    }

    const forceReload = () => {
        setReload((prev) => !prev);
    }

    if (loading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <Paper>
            <QuestionsToolbar exerciseId={exercise.id} setAddQuestionOpen={setAddQuestionOpen} selectedIds={selected} forceReload={forceReload} />
            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                            </TableCell>
                            <TableCell>Question</TableCell>
                            <TableCell>Reponse</TableCell>
                            <TableCell>Auteur</TableCell>
                            <TableCell>Matiere</TableCell>
                            <TableCell>Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((row) => (
                            <TableRow
                                key={row.id}
                                hover
                                onClick={() => handleRowClick(row)}
                                style={{ cursor: 'pointer' }}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selected.includes(row.id)}
                                        onChange={() => handleSelect(row.id)} />
                                </TableCell>
                                <TableCell>{row.question_type.typeCode !== 'DICTATION'
                                    ? <OpenQuestionOutput content={row.text} />
                                    : <AudioComponent audioFile={row.audioFile} />}
                                </TableCell>
                                <TableCell>{row.authorAnswer}</TableCell>
                                <TableCell>{row.user.firstName}</TableCell>
                                <TableCell>{row.subject.name}</TableCell>
                                <TableCell>{row.question_type.typeName}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >

            <AddQuestionDialog
                open={addQuestionOpen}
                exercise={exercise}
                handleClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setAddQuestionOpen(false);
                    }

                    forceReload();
                }} />
        </Paper>
    );
}

function QuestionsToolbar({ exerciseId, setAddQuestionOpen, selectedIds, forceReload }) {
    const [removeDisabled, setRemoveDisabled] = useState(true);
    const [notRemovable, setNotRemovable] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                let enable = true;

                if (selectedIds.length === 0) {
                    setRemoveDisabled(true)
                } else {
                    for (let selectedId of selectedIds) {
                        const hasCurrentId = notRemovable.find(obj => obj.id === selectedId);
                        if (hasCurrentId && !hasCurrentId.removable) {
                            setRemoveDisabled(true);
                            enable = false;
                            break;
                        } else if (hasCurrentId && hasCurrentId.removable) {
                            continue;
                        }
                        else {
                            const removable = await ExerciseService.isQuestionRemovableFromExercise(exerciseId, selectedId);
                            setNotRemovable((prev) => {
                                return [...prev, { id: selectedId, removable }]
                            });

                            if (!removable) {
                                setRemoveDisabled(true);
                                enable = false;
                                break;
                            }
                        }
                    }

                    if (enable) {
                        setRemoveDisabled(false);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [selectedIds]);

    const handleQuestionRemove = async () => {
        try {
            const res = await ExerciseService.removeQuestions(exerciseId, selectedIds);
            forceReload();
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Toolbar
            sx={[
                {
                    display: 'flex',
                    justifyContent: 'space-between',
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                },
            ]}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Exercices trouvés
            </Typography>

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Button color="primary" sx={{ whiteSpace: 'nowrap', marginRight: '10px' }} onClick={() => { setAddQuestionOpen(true) }} startIcon={<Add />}>
                    Ajouter des questions
                </Button>
                <Button
                    color="primary"
                    sx={{ whiteSpace: 'nowrap', marginRight: '10px' }}
                    onClick={handleQuestionRemove}
                    startIcon={<Delete />}
                    disabled={removeDisabled}>
                    Enlever
                </Button>
            </Box>
        </Toolbar>
    );
}

const AudioComponent = ({ audioFile }) => {    
    const audioRef = useRef(null);
    const [audioLoading, setAudioLoading] = useState(false);

    const fetchAndPlayAudio = async (audioFile) => {
        setAudioLoading(true);
        try {
            const response = await AudioService.getAudioFile(audioFile);
            const byteArray = await response.arrayBuffer();
            const blob = new Blob([byteArray], { type: 'audio/mpeg' });
            const blobUrl = URL.createObjectURL(blob);

            const audio = audioRef.current;
            audio.src = blobUrl;
            audio.load();
            audioRef.current.muted = true;

            if (audioRef.current.paused) {
                await audioRef.current.play();
                audioRef.current.muted = false;
            } else {
                audioRef.current.pause();
            }
        } catch (error) {
            console.error('Error fetching audio:', error);
        } finally {
            setAudioLoading(false);
        }
    };

    return (
        <Box>
            {audioLoading ? (
                <CircularProgress />
            ) : (
                <Button variant="contained" onClick={() => fetchAndPlayAudio(audioFile)}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <SmartDisplay />
                </Button>
            )}

            <audio preload="auto" ref={audioRef} />
        </Box>
    );
};

const OpenQuestionOutput = ({ content }) => {
    const sanitizedHtml = DOMPurify.sanitize(content);

    return (
        <div
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
            style={{ fontFamily: 'Arial, sans-serif' }}
        />
    );
}

export default ListQuestionCpnt;