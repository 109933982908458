import {
  Routes,
  Route,
  useNavigate,
  createSearchParams,
} from "react-router-dom";

import Navbar from "./components/header/Navbar";
import NotFound from "./pages/not-found/NotFound";

import Home from "./pages/home/home";
import ContactUs from "./pages/contact-us/ContactUs";
import AboutUs from "./pages/about-us/AboutUs";
import PrivacyPolicy from "./pages/privacy-policy";
import AddQuestionPage from "./pages/questions/add-question.page";
import SignIn from "./pages/authentication/sign-in";
import RemoveAccountInfo from "./pages/public/remove-account-info";
import ListExercise from "./pages/exercises/list-exercise";
import { createTheme, ThemeProvider } from "@mui/material";
import DetailExercise from "./pages/exercises/detail/detail-exercise";

function App() {
  const navigate = useNavigate();

  const onSearch = (searchQuery) => {
    navigate(`/?${createSearchParams({ q: searchQuery })}`);
  };

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Navbar onSearch={onSearch} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/list-exercise" element={<ListExercise />} />
          <Route path="/detail-exercise/:id" element={<DetailExercise />} />
          <Route path="/add-question" element={<AddQuestionPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/remove-account-info" element={<RemoveAccountInfo />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#4CAF50',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#8bc34a',
    },
  },
});

export default App;
