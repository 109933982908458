import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QuestionService from "../../../services/question.service";
import ListQuestionCpnt from "./list-question.cpnt";


const DetailExercise = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedRow } = location.state || {};

    return (
        <Box
            sx={{
                width: '100%',
                padding: 2,
                boxShadow: 3,
                borderRadius: 1,
                marginBottom: 2,
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    width: '100%',
                    marginBottom: 2,
                }}
            >
                <Typography variant="h4">
                    Details - {selectedRow.name}
                </Typography>

                <Button variant="contained" color="primary" startIcon={<ArrowBackIos />} onClick={() => { navigate('/list-exercise') }}>
                    Retourner
                </Button>
            </Stack>

            <Box>
                <ListQuestionCpnt exercise={selectedRow} />
            </Box>
        </Box>
    );
}

export default DetailExercise;